import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  addTransaction,
  getAllCustomers,
  getAllService,
  searchCustomer,
} from "../../service/api.service";
import CustomModal from "../../common/Modal/CustomModal";
import { CircularProgress } from "@mui/material";
import useDebounce from "../../hooks/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
const transactionList = [{ title: "credit" }, { title: "debit" }];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddTransaction(props) {
  const { customer } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState<Dayjs | null>(dayjs(null));
  const [tvalue, setTValue] = React.useState<Dayjs | null>(dayjs());

  const handleTransactionSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    addTransaction({
      date: tvalue,
      customerId: selectedCustomerId,
      serviceId: selectedService,
      amount: data.get("amount"),
      type: data.get("type"),
      description: data.get("description"),
    })
      .then((res) => {
        console.log("Transaction response:", res);

        setSnackbarSeverity("success");
        setSnackbarMessage("Transaction added successfully");
        setSnackbarOpen(true);
        props?.setRefresh(true);
        handleClose();
      })
      .catch((error) => {
        console.error("Error adding transaction:", error);

        setSnackbarSeverity("error");
        setSnackbarMessage("Error adding transaction");
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const [isAccountOpen, setIsAccountOpen] = React.useState(false);
  const [isServiceOpen, setIsServiceOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly any[]>([]);
  const [accInput, setAccInput] = useState<any>(null);
  const debounceAccNum = useDebounce(accInput, 250);
  const [services, setServices] = useState<any>([]);
  const loading = isAccountOpen && options.length === 0;
  const serviceLoading = isServiceOpen && services.length === 0;

  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const fetchOptions = async (pageNum: number) =>
    getAllCustomers({ page: pageNum, limit: 10 })
      .then((res) => {
        let filteredOptions = res?.customers?.map(
          ({ _id, firstName, lastName, accounts }) => ({
            customerId: _id,
            name: `${firstName} ${lastName ?? ""}`,
            account: accounts?.filter(({ isActive }) => isActive)?.[0]
              ?.accountNumber,
          })
        );
        return filteredOptions ? filteredOptions : [];
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        return [];
      });
  // Handle loading more data when scroll reaches bottom
  const loadMoreData = async () => {
    if (loadingOptions) return; // Prevent loading if already loading
    setLoadingOptions(true);
    const newOptions = await fetchOptions(page);
    setOptions((prev) => [...prev, ...newOptions]);
    setPage((prev) => prev + 1);
    setLoadingOptions(false);
    if (newOptions?.length < 10) setHasMore(false); // Stop if no more data
  };

  const handleCutomerId = (e, newValue) => {
    setSelectedCustomerId(newValue?.customerId);
  };

  const handleAccNumChange = (e, newValue) => {
    setAccInput(newValue);
  };

  const [selectedService, setSelectedService] = useState(null);

  const handleService = (e, newValue) => {
    setSelectedService(newValue?._id);
  };

  useEffect(() => {
    if (customer) {
      setSelectedCustomerId(customer?.customerId);
      setOptions([customer]);
    }
  }, [customer]);

  useEffect(() => {
    console.log("debounceAccNum", debounceAccNum);
    if (debounceAccNum)
      searchCustomer({ search: debounceAccNum, page: 1, limit: 10 }).then(
        (response) => {
          if (response) {
            let filteredOptions = response?.customers?.map(
              ({ customerId, firstName, lastName, account }) => ({
                customerId: customerId,
                name: `${firstName} ${lastName ?? ""}`,
                account: account,
              })
            );
            setOptions(filteredOptions);
            setPage(1);
            setHasMore(filteredOptions?.length === 10);
          }
        }
      );
    else {
      getAllCustomers({ page: 1, limit: 10 }).then((res) => {
        let filteredOptions = res?.customers?.map(
          ({ _id, firstName, lastName, accounts }) => ({
            customerId: _id,
            name: `${firstName} ${lastName ?? ""}`,
            account: accounts?.filter(({ isActive }) => isActive)?.[0]
              ?.accountNumber,
          })
        );
        setOptions(filteredOptions ? filteredOptions : []);
        setPage(1);
        setHasMore(filteredOptions?.length === 10);
      });
    }
  }, [debounceAccNum]);

  useEffect(() => {
    if (open) setTValue(dayjs());

    return () => {
      setTValue(null);
    };
  }, [open]);

  React.useEffect(() => {
    loadMoreData();
  }, []);

  React.useEffect(() => {
    let active = true;

    if (!serviceLoading) {
      return undefined;
    }

    if (active) {
      getAllService({}).then((res) => setServices(res?.services?.services));
    }

    return () => {
      active = false;
    };
  }, [serviceLoading]);

  const renderAccuntOptions = (props, option) => (
    <div
      {...props}
      key={option?.customerId}
      style={{
        padding: "8px",
        borderBottom: "1px solid #ccc",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <div>{option?.name ?? `${option?.firstName} ${option?.lastName}`}</div>
      <div style={{ fontWeight: "bold" }}>{option?.account}</div>
    </div>
  );

  return (
    <React.Fragment>
      <Button onClick={handleOpen} sx={{ marginTop: 2, marginLeft: 2 }}>
        Add Transaction
      </Button>
      {open ? (
        <CustomModal
          title={"Add Transaction"}
          open={open}
          handleClose={handleClose}
        >
          <Box>
            <form onSubmit={handleTransactionSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" spacing={1}>
                    <Chip label="Update Customers Txns..." />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Date"
                          value={tvalue}
                          onChange={(newTValue) => setTValue(newTValue)}
                          format="LL"
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="size-small-outlined-multi"
                    size="small"
                    options={options}
                    open={isAccountOpen}
                    defaultValue={customer ?? null}
                    onOpen={() => {
                      setIsAccountOpen(true);
                    }}
                    onClose={() => {
                      setIsAccountOpen(false);
                    }}
                    onChange={handleCutomerId}
                    onInputChange={handleAccNumChange}
                    loading={loading}
                    isOptionEqualToValue={(option, value) =>
                      option?.customerId === value?.customerId
                    }
                    getOptionLabel={(option) => option?.account ?? ""}
                    renderOption={renderAccuntOptions}
                    filterOptions={(o) =>
                      accInput
                        ? o?.filter(
                            (v) =>
                              v.name?.includes(accInput) ||
                              v.account?.includes(accInput)
                          )
                        : o
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Account Number"
                        placeholder="type to search..."
                        name="customerId"
                        // inputProps={{
                        //   ...params.InputProps,
                        //   endAdornment: (
                        //     <React.Fragment>
                        //       {loading ? (
                        //         <CircularProgress color="inherit" size={20} />
                        //       ) : null}
                        //       {params.InputProps.endAdornment}
                        //     </React.Fragment>
                        //   ),
                        // }}
                      />
                    )}
                    // ListboxComponent={(props) => (
                    //   <InfiniteScroll
                    //     {...props}
                    //     dataLength={options.length}
                    //     next={loadMoreData}
                    //     hasMore={hasMore}
                    //     loader={<CircularProgress color="primary" size={24} />}
                    //     scrollThreshold={0.9}
                    //   />
                    // )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    // multiple
                    id="size-small-outlined-multi"
                    size="small"
                    options={services}
                    defaultValue={services?.[0] ?? null}
                    open={isServiceOpen}
                    onOpen={() => {
                      setIsServiceOpen(true);
                    }}
                    onClose={() => {
                      setIsServiceOpen(false);
                    }}
                    onChange={handleService}
                    loading={serviceLoading}
                    isOptionEqualToValue={(option, value) =>
                      option?._id === value?._id
                    }
                    getOptionLabel={(option) => option?.name ?? ""}
                    renderOption={(props, option) => (
                      <span {...props}>{option?.name}</span>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Services Type"
                        placeholder="add..."
                        name="serviceId"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    // multiple
                    id="size-small-outlined-multi"
                    size="small"
                    options={transactionList}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Transaction Type"
                        placeholder="add..."
                        name="type"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl size="small" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Amount
                    </InputLabel>
                    <OutlinedInput
                      required
                      id="outlined-adornment-amount"
                      endAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                      label="Amount"
                      type="number"
                      name="amount"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  style={{
                    // marginTop: "2%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  item
                  xs={12}
                  sm={12}
                >
                  <Button
                    style={{ marginRight: "16px" }}
                    fullWidth
                    variant="outlined"
                    endIcon={<CancelIcon />}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    endIcon={<SendIcon />}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </CustomModal>
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
}
